import { Skeleton } from '@mui/material';
import { compose } from 'redux';
import store from 'store';
import { API_REGION } from 'state-domains/constants';
import { AuthenticationStatus, OAuth2TokenOptions } from 'state-domains/domain';
import { ContentLoading } from 'src/components/ContentLoading';
import { Login } from 'src/components/Login';
import { withRouter } from 'src/routes';
import React from 'react';

import { connectToState } from './withAuth.connect';
import { WithAuthProps, WithAuthState } from './withAuth.types';

export const withAuthBase = (WrappedComponent: React.ComponentType) =>
    class extends React.Component<WithAuthProps, WithAuthState> {
        public get oauth2TokenOptions(): OAuth2TokenOptions {
            const { location } = this.props;

            const preventOAuth2LoginRedirection = location.pathname === '/';
            return {
                preventOAuth2LoginRedirection,
            };
        }

        componentDidMount() {
            const { oauth2Token } = this.props;
            const queryParams = new URLSearchParams(this.props.location?.search ?? '');
            if (queryParams.has('region')) {
                store.set(API_REGION, queryParams.get('region') ?? 'us');
                queryParams.delete('region');
                this.props.setSearchParams?.(queryParams);
            }
            oauth2Token(this.oauth2TokenOptions);
        }

        constructor(props: WithAuthProps) {
            super(props);
            this.state = { reDance: false };
        }

        componentDidUpdate(prevProps: WithAuthProps) {
            const { authenticationStatus } = this.props;
            if (this.props.location.pathname !== prevProps.location.pathname) {
                this.props.oauth2Token(this.oauth2TokenOptions);
            }

            if (
                authenticationStatus === AuthenticationStatus.NotAuthenticated &&
                prevProps.authenticationStatus === AuthenticationStatus.ReAuthenticating
            ) {
                this.setState({ reDance: true });
            }

            if (
                authenticationStatus === AuthenticationStatus.Authenticated &&
                prevProps.authenticationStatus === AuthenticationStatus.ReAuthenticating &&
                this.props.hasFailedState
            ) {
                /*
                 * There will be times where a user with correct authentication tries to load a page but the central token
                 * becomes invalid for some reason, eg. user info updated in Central. Do the authentication dance to grab
                 * a new token and reload page.
                 */
                this.setState({ reDance: true });
            }
        }

        render() {
            const authenticationStatus = this.props.authenticationStatus;
            const loggedOutState = this.props.loggedOutState;
            if (this.state.reDance) {
                return [<WrappedComponent key="authBase" {...this.props} />];
            }

            if (this.props.isCurrentUserFailed) {
                return <Login error="401" />;
            }

            if (authenticationStatus === AuthenticationStatus.NotAuthenticated) {
                return <Login />;
            }

            if (
                authenticationStatus === AuthenticationStatus.Authenticating ||
                authenticationStatus === AuthenticationStatus.Unknown
            ) {
                if (loggedOutState)
                    return (
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            sx={{ height: '100vh', width: '100%' }}
                        />
                    );
                return <ContentLoading isFailed={false} skeletonLoading fullAppSkeleton />;
            }

            // we needed to explicitly set this key to avoid an error
            return <WrappedComponent key={undefined} {...this.props} />;
        }
    };

export const withAuth = (WrappedComponent: React.ComponentType) =>
    compose(connectToState, withRouter, withAuthBase)(WrappedComponent);
